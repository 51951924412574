@import "../theme";

nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 15px;
  background-color: $blue-dark;
}

nav button {
  max-width: 100px;
}

nav a {
  height: 28px;
}

nav img {
  height: 28px;
}
