@import "../theme";

@mixin appendButtonTheme($colorBack, $colorFront) {
  background-color: $colorBack;
  color: $colorFront;
  border-color: $colorFront;
}

button {
  //   padding: 10px 50px;
  width: 100%;
  max-width: 240px;
  height: 40px;
  background-color: transparent;
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer;

  &.--master {
    @include appendButtonTheme($blue-dark, $gold);
    border: 0px;
  }
  &.--slave {
    @include appendButtonTheme(transparent, $gold);
  }
  &.--max-width {
    width: 100%;
  }

  &:focus,
  &:hover,
  &:active {
    outline: 0px;
  }
}
