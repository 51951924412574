* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-image: url("assets/imgs/header-start.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
