@import "../theme";

article.content-box {
  position: relative;
  width: 85%;
  max-width: 400px;
  padding: 1rem;
  margin: 10px 0px;
  background-color: white;
  background-color: rgba(255, 255, 255, 0.85);
  color: #162636;
  border-radius: 20px;

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: inherit;
  }

  h2 {
    padding-bottom: 0.25rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    border-bottom: 1px solid $blue-dark;
  }

  .content {
    font-size: 0.95rem;
  }

  ul {
    li {
      list-style-type: none;
      // margin: 10px 0px;
      padding: 5px 0px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}
