@import "../theme";

label,
span {
  display: inline-block;
  width: 110px;
  padding-right: 10px;
  font-size: 0.9rem;
  text-align: right;
}
span {
  width: 20px;
  padding-right: 0px;
  padding-left: 5px;
  text-align: left;
  font-weight: 500;
}

input {
  height: 40px;
  width: 100px;
  padding-left: 19px;
  padding-right: 10px;
  font-size: 1.15rem;
  text-align: center;
  border-radius: 20px;
  border: 0px;

  &:focus,
  &:active,
  &:hover {
    outline: 0px;
    border: 1px solid lighten($blue-dark, 55%);
  }
}
