ul.preview-ul {
  li {
    border-bottom: 1px solid #999;
    &:last-of-type {
      border-bottom: 0px;
    }
    label,
    span {
      display: inline-block;
    }
    label {
      width: 120px;
    }
    span {
      width: 120px;
      text-align: right;
      letter-spacing: 1px;
    }
  }
}
